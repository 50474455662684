/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';
import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input'

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
$(document).ready(function () {

    bsCustomFileInput.init();

    $(document).on("click", '.displayfield', function (event) {
        var id = $(this).attr('rel');
        $('.original').hide();

        if (id > 0)
        {
            $('#empModal .modal-body').addClass('loading');
            // AJAX request
            $.ajax({
                url: 'send-modification-token/' + id,
                type: 'post',
                success: function (response) {
                    // Add response in Modal body
                    $('#empModal .modal-body').removeClass('loading');
                    $('.modified').show();
                }
            });
        } else
        {
            $('.modified').show();
        }
    });

    $(document).on("click", '.viewdata', function (event) {
        $('.original').show();
        $('.modified').hide();
    });

    $('.userinfo').click(function () {

        var id = $(this).data('id');

        // AJAX request
        $.ajax({
            dataType: 'json',
            url: 'detail/' + id,
            type: 'get',
            success: function (response) {
                // Add response in Modal body
                $('#empModal .modal-body').html(response.body);
                $('#empModal .modal-footer').html(response.footer);
                $('#empModal .modal-title').html(response.title);
                // Display Modal
                $('#empModal').modal('show');
            }
        });
    });

    $('.userregister').click(function () {

        // AJAX request
        $.ajax({
            url: 'registerForm',
            type: 'get',
            success: function (response) {
                // Add response in Modal body
                $('#exampleModal .modal-body').html(response);
                // Display Modal
                $('#exampleModal').modal('show');
            }
        });
    });

});
